<template>
    <div>
        <section class="">
            <div class="container" style="padding: 20pt;margin-top: 40pt;">
                <div class="col-md-8 col-md-offset-2">
                <div class="heading text-center">
                    <h2><span style="color: #206A50;">Statistik</span></h2>
                    <div class="line"></div>
                    <br>
                </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ab-sec">

                            <div class="row">
                                <div class="col-md-4 text-left">
                                    <!-- <span class="font_title font_white">Produksi Telur</span> -->
                                </div>
                                <div class="col-md-2 text-left">
                                    <!-- <select class="form-control"  v-model="form.master_kelas_ternak_id" @change="cari_data(), getJenis()">
                                        <option value="">-- Semua Kelas Ternak --</option>
                                        <option v-for="data in list_kelas" :key="data.master_kelas_ternak_id" :value="data.master_kelas_ternak_id">{{data.uraian}}</option>
                                    </select> -->
                                </div>
                                <div class="col-md-3 text-left">
                                    <select class="form-control"  v-model="kecamatan_id" @change="load_data()">
                                        <option value="">-- Semua Kecamatan --</option>
                                        <option v-for="data in list_kecamatan" :key="data.kecamatan_id" :value="data.kecamatan_id">[{{data.kode_kecamatan}}]. {{data.nama_kecamatan}}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 text-left">
                                    <select class="form-control"  v-model="master_thn_id" @change="load_data()" style="widht:100%">
                                            <option value="2016">2016</option>
                                            <option value="2017">2017</option>
                                            <option value="2018">2018</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                        </select>
                                </div>
                            </div>
                            <br/>
                            <div class="row">
				

                                    <div class="row cm-fix-height shaddow">
                                        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2"  v-for="data in list_jenis" :key="data.master_jenis_ternak_id">
                                            <a class="panel panel-default thumbnail cm-thumbnail">
                                                <div class="panel-body text-center" style="min-height: 75px;">
                                                    <span class="svg-48">
                                                        <img :src="file_path+data.file" style="width: 50px">
                                                    </span>
                                                    <br/>
                                                    <span class="card_title">{{data.uraian}}</span> <br>
                                                    <span class="card_body">{{format_uang(data.jumlah_populasi)}}</span>

                                                </div>
                                            </a>
                                        </div>

                                    </div>

                                    <hr>
                                    
                                    

                    <!-- dinamika_eksport -->



                            </div>

                    
                        <br>
                                    <div class="table-responsive shaddow" >
                                        <!-- <canvas id="jenis_ternak_tahun" width="1500" height="350" responsive="false"></canvas> -->
                                        <canvas class="canvas" id="jenis_ternak_tahun" responsive="false" height="300px"></canvas>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <select class="form-control" v-model="master_jenis_ternak_id" @change="load_data()">
                                                <option v-for="data in list_jenis_ternak" :key="data.master_jenis_ternak_id" :value="data.master_jenis_ternak_id">{{data.uraian}}</option>
                                            </select>

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        
                                        <div class="col-md-6" style="">
                                            <div class="shaddow">
                                                <canvas class="canvas1" id="dinamika_eksport" height="300px"></canvas>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="shaddow">
                                                <canvas class="canvas1" id="dinamika_import" height="300px"></canvas>

                                            </div>
                                        </div>
                                    </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import Chart from 'chart.js';

const URL_APP = "https://server.populasi-dpkh.konaweselatankab.go.id/";
const URL_JENIS = URL_APP + "api/v1/statistik_peternakan_publish_home/";
const URL_KECAMATAN = URL_APP + "api/v1/statistik_peternakan_publish_kecamatan/";
const FILE_LOAD = URL_APP + "uploads/";


export default {
	data() {
		return {
            master_thn_id : '',
            kecamatan_id : '',
            master_jenis_ternak_id : '',



			list_jenis :[],
            list_kelas :[],
            list_kecamatan :[],
            list_jenis_ternak :[],

			file_old: "",
            file_path: FILE_LOAD,
            

			page_first: 1,
            page_last: 0,
			cari_value: "",
			cek_load_data : true,
		}
	},
	methods: {
		getJenis: function() {
            fetch(URL_JENIS + "jenis_ternak", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    master_thn_id : this.master_thn_id,
                    kecamatan_id : this.kecamatan_id,
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    // console.log(res_data);
                    this.list_jenis = res_data;

            });
        },

        getJenisTernak: function() {
            fetch(URL_JENIS + "getJenisTernak", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({})
            })
                .then(res => res.json())
                .then(res_data => {
                    // console.log(res_data);
                    this.list_jenis_ternak = res_data;
                    this.master_jenis_ternak_id = res_data[0].master_jenis_ternak_id
                    // console.log(this.master_jenis_ternak_id);
                    this.dinamika_eksport();
                    this.dinamika_import();

            });
        },


        jenis_ternak_tahun : function(){

            fetch(URL_JENIS + "jenis_ternak_tahun", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    master_thn_id : this.master_thn_id,
                    kecamatan_id : this.kecamatan_id,
                })
            })
                .then(res => res.json())
                .then(res_data => {

                    var labelsku = [];
                    var dataku1 = []; var dataku2 = []; var dataku3 = []; var dataku4 = []; var dataku5 = [];

                    for (let i = 0; i < res_data.length; i++) {
                        labelsku.push(res_data[i].uraian);
                        dataku1.push(res_data[i].tahun1);
                        dataku2.push(res_data[i].tahun2);
                        dataku3.push(res_data[i].tahun3);
                        dataku4.push(res_data[i].tahun4);
                        dataku5.push(res_data[i].tahun5);
                        
                    }


                    new Chart(document.getElementById("jenis_ternak_tahun"), {
                    type: 'bar',
                    data: {
                        labels: labelsku,
                        datasets: [
                            {
                                label: "2016",
                                backgroundColor: "#FFDF5E",
                                data: dataku1
                            }, 
                            {
                                label: "2017",
                                backgroundColor: "#EBC7BC",
                                data: dataku2
                            },
                            {
                                label: "2018",
                                backgroundColor: "#D75EFF",
                                data: dataku3
                            },
                            {
                                label: "2019",
                                backgroundColor: "#B2CDEB",
                                data: dataku4
                            },
                            {
                                label: "2020",
                                backgroundColor: "#80FFA5",
                                data: dataku5
                            },
                        ]
                        },
                        options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        title: {
                            display: true,
                            text: 'Jumlah Populasi Ternak'
                        }
                    }
                });

            });


            
        },

        dinamika_eksport : function(){
            var narasi = 'Jumlah Eksport '
            var id_canvas = 'dinamika_eksport';
            var judul = "Dinamika Eksport "
            
            fetch(URL_JENIS + "dinamika_eksport", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    master_thn_id : this.master_thn_id,
                    kecamatan_id : this.kecamatan_id,
                    master_jenis_ternak_id : this.master_jenis_ternak_id,
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    this.lineChart(res_data, narasi, id_canvas, judul);

                    

            });



            
        },
        dinamika_import : function(){
            var narasi = 'Jumlah Import '
            var id_canvas = 'dinamika_import';
            var judul = "Dinamika Import "
            
            fetch(URL_JENIS + "dinamika_import", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    master_thn_id : this.master_thn_id,
                    kecamatan_id : this.kecamatan_id,
                    master_jenis_ternak_id : this.master_jenis_ternak_id,
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    this.lineChart(res_data, narasi, id_canvas, judul);

                    

            });



            
        },

        getKec: function() {
            // this.cek_load_data = true;
            fetch(URL_KECAMATAN , {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    // console.log(res_data);
                    this.list_kecamatan = res_data;
                        
            });
        },


		selectData : function(data){
			this.form=  {
				master_jenis_ternak_id : data.master_jenis_ternak_id,
				master_kelas_ternak_id : data.master_kelas_ternak_id,
				uraian : data.uraian,
				keterangan : data.keterangan,
				file : data.file,
			};
			this.file_old =  data.file;
        },
        format_uang : function(nilai){
            return nilai.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        
        load_data : function(){
            this.getJenis();
            this.jenis_ternak_tahun();
            this.dinamika_eksport();
            this.dinamika_import();
        },

        lineChart : function(res_data, narasi, id_canvas, judul){
            new Chart(document.getElementById(id_canvas), {
                type: 'line',
                data: {
                    labels: [2016,2017,2018,2019,2020],
                    datasets: [{ 
                        data: [res_data.tahun1,res_data.tahun2,res_data.tahun3,res_data.tahun4,res_data.tahun5],
                        label: narasi + res_data.uraian,
                        borderColor: "#3e95cd",
                        fill: false
                    }
                    ]
                },
                options: {
                    legend: {display: false},
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                    display: true,
                    text: judul+res_data.uraian
                    }
                }
            });
        },

		// ================== TAMBAHAN ====================
		// ================== TAMBAHAN ====================
	

	},
	mounted () {
        this.getJenisTernak();
        var d = new Date();
        this.master_thn_id = d.getFullYear()-1;
        this.getKec();

        this.load_data();


        

	},
}
</script>