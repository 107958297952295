<template>
    <div class="container" style="padding: 20pt;margin-top: 40pt;">
        <br> <br>

        <div class="col-md-8 col-md-offset-2">
            <div class="heading text-center">
                <h2>Data </h2>
                <div class="line"></div>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ab-sec">
                <div class="pull-right form-group">
                    
                    <input v-model="cari_value" @keyup="cari_data()" class="form-control form-control-lg" type="text" placeholder="Cari Data">
                </div>
                <div class="col-md-12">

                    
                    <div class="table-responsive">
                        <table class="table" width="100%">
                            <thead>
                                <tr>
                                    <th width="5%">No</th>
                                    <th width="40%">Judul</th>
                                    <th width="40%">Deskripsi</th>
                                    <th width="10%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index ) in list_data" :key="data.id">
                                    <th scope="row">{{index+1}}</th>
                                    <td v-html="data.judul"></td>
                                    <td v-html="data.deskripsi"></td>
                                    <td class="text-center">
                                        <!-- <button type="button" class="btn btn-primary btn-sm">
                                            Lihat
                                        </button> -->
                                        <button @click="selectData(data)" class="btn-danger" title="Lihat Data" data-toggle="modal" data-target="#m_lihat_data">
                                            <i class="fa fa-download" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                   
                    <div class="modal fade" id="m_lihat_data"  role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Detail File</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <iframe :src="file_path+form.file" width="100%" height="500px"></iframe>
                                </div>
                                <div class="modal-footer">

                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>

        <hr>
            <br>
            <div class="text-center">
              <button @click="btn_prev" type="button" class="btn btn-xs bg-blue waves-effect" id="pref">
                           <i class="fa fa-arrow-circle-o-left" aria-hidden="true" style="font-size:150%"></i>
                       </button>
                       &nbsp;
                       <span style="font-size: 85%; color: #a5a5a5">
                           <b>{{page_first}} - {{page_last}}</b>
                       </span>
                       &nbsp;
                       <button @click="btn_next" type="button" class="btn btn-xs bg-blue waves-effect" id="next">
                           <i class="fa fa-arrow-circle-o-right" aria-hidden="true" style="font-size:150%"></i>
                       </button>
            </div>
            <br>
        <hr>
    </div>
</template>

<script>
import store from '@/store/index.js';
    const URL_APP = store.state.url.URL_APP;
    
const ADD_URL = URL_APP + "api/v1/publish_disnakkeswan_data/";
const FILE_LOAD = URL_APP + "uploads/";


export default {

    data() {
        return {
            data_load : false,
            form : {
                id : '',
                judul : '',
                sumber : '',
                isi : '',
                deskripsi : '',
                file : '',
                unit_kerja : '',
                status : '',
                keterangan : '',
            },
            id :'',
            file_path: FILE_LOAD,

            list_data: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",
            kategori: "RPJMN",
        }
    },
    methods: {
    getView: function() {
      fetch(ADD_URL + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data_ke: this.page_first,
          cari_value: this.cari_value,
          kategori: this.kategori
        })
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_data = res_data.data;
            this.page_last = res_data.jml_data;
        });
    },

    selectData: function(data) {
        this.form = {
            id : data.id,
            // kategori : data.kategori,
            judul : data.judul,
            deskripsi : data.deskripsi,
            file : data.file,
        };
    },

    mdl_exit: function() {
      this.judul = "";
      this.deskripsi = "";
      this.file = "";
      this.file_old = "";
    },

  // ================== PAGINASI ====================
    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){

        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
  // ================== PAGINASI ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {

        this.getView();

    }
};
</script>
