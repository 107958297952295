<template>
    <div>
        <section class="">
            <div class="container" style="padding: 20pt;margin-top: 40pt;">
                <div class="col-md-8 col-md-offset-2">
                <div class="heading text-center">
                    <h2>Tugas & <span style="color: #206A50;">Fungsi</span></h2>
                    <div class="line"></div>
                    <br>
                </div>
                </div>
                <div class="row">
                <div class="col-md-12 ab-sec">
                    <div class="col-md-12">
                        <p  v-html="isi"></p>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

    //const URL_APP = 'http://localhost:5005/';   
    import store from '@/store/index.js';
    const URL_APP = store.state.url.URL_APP;
    const UNIT_KERJA = store.state.url.UNIT_KERJA;
    const URL = URL_APP + "api/v1/publish_disnakkeswan_tupoksi/";
    

export default {  

  data() {
			return {
                id: "",
                
                unit_kerja : UNIT_KERJA,
            
                isi: "",

			}
		},

    methods: {
            
			getIsi: function() {
                fetch(URL, {
                    method: "get",
                    headers: {
                        "content-type": "application/json",
                    },

                })
                    .then(res => res.json())
                    .then(res_data => {

                        // console.log("hasil ", res_data);
                        var tugas_pokok = res_data[0];

                        this.isi = tugas_pokok.isi;

                    });
            },
            

        },
		mounted() {

            this.getIsi();
            

		},


}
</script>