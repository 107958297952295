<template>
    <div>
        <section class="">
            <div class="container" style="padding: 20pt;margin-top: 40pt;">
                <div class="col-md-8 col-md-offset-2">
                <div class="heading text-center">
                    <h2>Struktur <span style="color: #206A50;">Organisasi</span></h2>
                    <div class="line"></div>
                    <br>
                </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ab-sec">
                        <div class="row">
                            <div class="features-sec">
                                <div v-for="(data) in list_data" :key="data.id" class="col-md-4 col-sm-6 col-xs-6 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="0ms">
                                    <div class="media service-box">
                                        <div> 
                                            <img :src="file_path+data.foto" class="img-responsive" style="height:250px; width: auto;" alt="Image">
                                        </div>
                                        <div class="media-body text-center">
                                            <h6><span style="color: #206A50;">{{ data.judul }}</span></h6>
                                            <p v-html="data.isi" ></p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

    //const URL_APP = 'http://localhost:5005/';  
    import store from '@/store/index.js';
    const URL_APP = store.state.url.URL_APP;
    // const UNIT_KERJA = store.state.url.UNIT_KERJA;
    const URL = URL_APP + "api/v1/publish_disnakkeswan_struktur_organisasi/";
    const FILE_LOAD = URL_APP + "uploads/";
    

export default {  

    data() {
        return {
            data_load : false,
            form : {
                id : '',
                judul : '',
                isi : '',
                foto : '',
                unit_kerja : '',
                status : '',
                createBy : '',
                createAt : '',
            },
            id :'',
            cek_load_data : true,
            file_old: "",
            file_path: FILE_LOAD,

            list_data: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",
        }
    },
    methods: {
    getView: function() {
        this.cek_load_data = true;
        fetch(URL + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            },
            body: JSON.stringify({
            data_ke: this.page_first,
            cari_value: this.cari_value,
            kategori: this.kategori,
            })
        })
        .then(res => res.json())
        .then(res_data => {
            // this.list_data = res_data.data;
            this.list_data = res_data;
            this.page_last = res_data.jml_data;
            this.cek_load_data = false;
            // console.log(res_data);
        });
    },

    selectData: function(data) {
        // console.log(data);
        this.form = {
            id : data.id,
            judul : data.judul,
            isi : data.isi,
            foto : data.foto,
            unit_kerja : data.unit_kerja,
            status : data.status,
            createBy : data.createBy,
            createAt : data.createAt,
        };
      },
      ke_alamat : function(data){
            window.open(data);
        },

    mdl_exit: function() {
      this.judul = "";
      this.uraian = "";
      this.file = "";
      this.file_old = "";
    },

  // ================== PAGINASI ====================
    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
            this.cek_load_data = true;
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){

        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
    onFileSelected: function(event) {
      this.form.foto = event.target.files[0];
    },
    convert_tgl: function(dateString) {
      var date = new Date(dateString);
      return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    },
  // ================== PAGINASI ====================

  // ================== MODAL ====================
      buka_modal: function(modalku) {
        var modal = document.getElementById(modalku);
        modal.style.display = "block";
      },

      tutup_modal: function(modalku) {
        var modal = document.getElementById(modalku);
        modal.style.display = "none";
      },

  // ================== MODAL ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {

        this.getView();

    }


}
</script>