<template>
    <div>
        <!--Navigation-->
            <header id="menu">
            <div class="navbar navbar-default navbar-fixed-top" style="background: linear-gradient(145deg,#206a50 15%,#153966 70%);">
                <div class="container">
                <div class="container-fluid">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>
                    <router-link to="/"> <a class="navbar-brand"  href="javascript:void(0)" :to="'/'"><img src="images/logo1.png" alt="" width="100%" height="70%"></a> </router-link></div>
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav">
                        <li class="active"> <router-link to="/">  <a class="scroll" href="javascript:void(0)">Beranda</a> </router-link>  </li>
                        <li> <router-link to="/Berita"> <a class="scroll" href="javascript:void(0)" :to="'/Berita'">Berita</a> </router-link> </li>
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#Informasi">Informasi <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background: linear-gradient(145deg,#206a50 15%,#153966 70%);">
                                <li> <router-link to="/pengumuman"> <a href="javascript:void(0)" :to="'/pengumuman'">Pengumuman</a> </router-link> </li>
                                <li> <router-link to="/regulasi"> <a  href="javascript:void(0)" :to="'/regulasi'">Regulasi</a> </router-link> </li>
                                <li> <router-link to="/statistik"> <a  href="javascript:void(0)" :to="'/statistik'">Statistik</a> </router-link> </li>
                                <!-- <li>  <a  href="javascript:void(0)" >Statistik</a>  </li> -->
                                     
                            </ul>
                        </li> 
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#Profil">Profil <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background: linear-gradient(145deg,#206a50 15%,#153966 70%);">
                                <li> <router-link to="/tugas_pokok"> <a href="javascript:void(0)" :to="'/tugas_pokok'">Tugas Pokok</a> </router-link> </li>
                                <li> <router-link to="/struktur_organisasi"> <a href="javascript:void(0)" :to="'/struktur_organisasi'">Struktur Organisasi</a> </router-link> </li>
                                <!-- <li> <router-link to="/tujuan_sasaran"> <a href="javascript:void(0)" :to="'/tujuan_sasaran'">Tujuan & Sasaran</a> </router-link> </li> -->
                                <li> <router-link to="/visi_misi"> <a href="javascript:void(0)" :to="'/visi_misi'">Visi & Misi</a> </router-link> </li>
                                     
                            </ul>
                        </li>
                        
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#Layanan">Layanan <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background: linear-gradient(145deg,#206a50 15%,#153966 70%);">
                                <li>   <a @click="push_link('2bm7uledkdcliwr38xp')" >Inseminasi Buatan</a>  </li>
                                <li>   <a @click="push_link('2bm7ule29f36llkk3szm')"  >Pemeriksaan Kebuntingan</a>  </li>
                                <li>   <a @click="push_link('2bm7ule2dhgylll5kf7m')"  >Pemeriksaan ATR</a>  </li>
                                <li>   <a @click="push_link('2bm7ule2dhgylll5klvy')"  >Pemberian Vaksin & Obat-obatan</a>  </li>
                                <li>   <a @click="push_link('2bm7ule2dhgylll5l59u')"  >Pelayanan Kesehatan Hewan</a>  </li>
                                <li>   <a @click="push_link('2bm7ule2dhgylll5lfe6')"  >Penyuluhan Kepada Petani/Peternak</a>  </li>
                                <li>   <a @click="push_link('2bm7ule2dhgylll5lmul')"  >Izin-izin Usaha Peternakan</a>  </li>
                                <li>   <a @click="push_link('2bm7ule2dhgylll5lwc0')"  >Asuransi Ternak</a>  </li>
                                <li>   <a @click="push_link('2bm7ule2dhgylll5lwc0')"  >Kredit Usaha Peternakan</a>  </li>
                                
                                
                                
                                     
                            </ul>
                        </li>
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#Bidang">Bidang <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background: linear-gradient(145deg,#206a50 15%,#153966 70%);">
                                <li> <router-link to="/sekretariat"> <a href="javascript:void(0)" :to="'/sekretariat'">Sekretariat</a> </router-link> </li>
                                <li> <router-link to="/sarana"> <a href="javascript:void(0)" :to="'/sarana'">Produksi Prasarana & Sarana</a> </router-link> </li>
                                <li> <router-link to="/penyuluhan"> <a href="javascript:void(0)" :to="'/penyuluhan'">Penyuluhan</a> </router-link> </li>
                                <li> <router-link to="/keswan"> <a href="javascript:void(0)" :to="'/keswan'">Kesehatan Hewan, Kesmavet, Pengolahan & Pemasaran</a> </router-link> </li>
                                <li> <router-link to="/bibit"> <a href="javascript:void(0)" :to="'/bibit'">Perbibitan & Produksi</a> </router-link> </li>
                                     
                            </ul>
                        </li>
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#Galeri">Galeri <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background: linear-gradient(145deg,#206a50 15%,#153966 70%);">
                                <li> <router-link to="/foto"> <a href="javascript:void(0)" :to="'/foto'">Foto</a> </router-link> </li>
                                <li> <router-link to="/video"> <a href="javascript:void(0)" :to="'/video'">Video</a> </router-link> </li>
                                
                            </ul>
                        </li>
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#data">Data & Dokumen <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background: linear-gradient(145deg,#206a50 15%,#153966 70%);">
                                <li> <router-link to="/data"> <a class="scroll" href="javascript:void(0)" :to="'/data'">Data</a> </router-link> </li>
                                <li> <router-link to="/dokumen"> <a class="scroll" href="javascript:void(0)" :to="'/dokumen'">Dokumen</a> </router-link> </li>
                                
                            </ul>
                        </li>
                       
                        
                        
                        
                        
                    </ul>
                    
                    </div>
                    <!-- /.navbar-collapse -->
                </div>
                <!-- /.container-fluid -->
                </div>
            </div>
            </header>
    </div>
</template>



<script>
export default {

    methods: {
        
        push_link : function(id){
            this.$router.push('/layanan?id='+id);
            location.reload();
        },

    },
}
</script>