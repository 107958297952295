import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url : {
      
       URL_APP : 'https://server-web.konaweselatankab.go.id/',
       UNIT_KERJA : 'BiE6vp8tPWcwSjxP6'
      //  UNIT_KERJA : 'zghjglbHFG'

    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
